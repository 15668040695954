import React from "react";
import "../home_right/style.css";
import Bryan from "../../assets/img/bry1.png";
import { Container, Row, Col, Image } from "react-bootstrap";
import SocialIcons from "../social_icons";

function Home_Right() {
  return (
    <Container className="home-right">
      <Row className="home-right-main justify-content-center align-items-center">
        <Col xl={12} className="text-center">
          <Image
            src={Bryan}
            className="home-right-main-img"
            alt="Image of Bryan"
          />
          <Row className="home-right-footer justify-content-center">
            <SocialIcons />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Home_Right;
