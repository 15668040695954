import React from "react";
import "../home_left/style.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import ReactTypingEffect from "react-typing-effect";
import { Link } from "react-router-dom";

function Home_Left() {
  return (
    <Container className="home-left">
      <Row className="home-left-main">
        <Col xl={12} className="home-left-main-col">
          <h1 className="first-line">Hello!</h1>
          <h2 className="second_line">
            I am <strong>Bryan Lomerio</strong>
          </h2>
          <h3>
            <ReactTypingEffect
              text="Junior Software Engineer ┃ Full-stack Developer | Programmer"
              className="typical"
              speed="150"
              eraseDelay="500000"
            />
          </h3>
          <p align="center">
            <a
              href="https://www.w3schools.com/css/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"
                alt="css3"
                width="25"
                height="25"
              />
            </a>
            <a
              href="https://expressjs.com"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://img.icons8.com/?size=128&id=2ZOaTclOqD4q&format=png"
                alt="express"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://git-scm.com/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg"
                alt="git"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://react.dev/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://img.icons8.com/?size=80&id=wPohyHO_qO1a&format=png"
                alt="react.js"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://www.w3.org/html/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"
                alt="html5"
                width="25"
                height="25"
              />
            </a>
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
                alt="javascript"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://www.mongodb.com/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg"
                alt="mongodb"
                width="25"
                height="25"
              />
            </a>
            <a
              href="https://nodejs.org"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg"
                alt="nodejs"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://www.python.org"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg"
                alt="python"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://www.php.net/manual/en/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://icongr.am/devicon/php-original.svg?size=128&color=currentColor"
                alt="php"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://api.jquery.com/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://icongr.am/devicon/jquery-plain.svg?size=128&color=1100ff"
                alt="jQuery"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://www.mysql.com/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://img.icons8.com/?size=100&id=rgPSE6nAB766&format=png&color=000000"
                alt="MySQL"
                width="25"
                height="25"
              />
            </a>

            <a
              href="https://www.codeigniter.com/"
              target="_blank"
              rel="noreferrer"
              className="pop-up-icon"
            >
              <img
                src="https://codeigniter.com/assets/icons/44521256.png"
                alt="CodeIgniter"
                width="25"
                height="25"
              />
            </a>
          </p>

          <Row className="justify-content-center">
            <Col xl={12} className="text-center">
              <Button
                variant="outline-light"
                size="lg"
                className="home-left-aboutmme-btn"
              >
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "white" }}
                  className="home-left-aboutme"
                >
                  About me
                </Link>
              </Button>
              {/*&nbsp;
              <Button
                variant="outline-light"
                size="lg"
                className="home-left-aboutmme-btn"
              >
                <a
                  href="https://my-journey-at-intelliseven.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  OJT Internship Journey
                </a>
              </Button>*/}
              &nbsp;
              <Button
                variant="outline-light"
                size="lg"
                className="home-left-aboutmme-btn"
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contact"
                  className="home-left-aboutme"
                >
                  Contact me
                </Link>
              </Button>
              &nbsp;
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Home_Left;
