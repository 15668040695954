import React, { Component } from "react";
import "../cv_img/style.css";
import cv1 from "../../assets/img/Lomerio-Resume_page-0001.jpg";
import cv2 from "../../assets/img/Lomerio-Resume_page-0002.jpg";
import cv3 from "../../assets/img/lomerio-resume.jpg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Roll from "react-reveal/Roll";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";
import pdfFile from "../../assets/img/Lomerio-Resume.pdf";

const images = [cv1, cv2, cv3];

export default class CRI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <Roll right>
        <Zoom right>
          <Flip right>
            <div style={{ position: "relative" }}>
              <img
                src={cv1}
                onClick={() => this.setState({ isOpen: true })}
                className="cv-image"
                alt="Bryan Lomerio"
              />
              <p
                className="click-to-view"
                onClick={() => this.setState({ isOpen: true })}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 50,
                  width: "100%",
                  textAlign: "center",
                  color: "red",
                  cursor: "pointer",
                }}
              >
                Click to view my resume. {images.length - 1} more image
                available.
              </p>
              <a
                href={pdfFile}
                download="Bryan_Lomerio-Resume.pdf"
                className="download-button"
              >
                Download Resume
              </a>
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + images.length - 1) % images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % images.length,
                    })
                  }
                />
              )}
            </div>
          </Flip>
        </Zoom>
      </Roll>
    );
  }
}
