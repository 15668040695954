import React, { useEffect } from "react";
import "../home/style.css";
import HomeLeft from "../../components/home_left";
import HomeRight from "../../components/home_right";
import Footer from "../../components/footer";
import { Container, Row, Col } from "react-bootstrap";
import Animate from "react-smooth";
import Particles from "react-particles-js";
import particles from "../../const/particle.js";
import ReactGa from "react-ga";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import organizationLogo from "../../assets/img/Ado.png";

function Analytics() {
  ReactGa.initialize("Anino##123++");
  ReactGa.pageview("bryanlomerio.com - Home Screen");
}

function Home() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  useEffect(() => {
    Analytics();
  }, []);

  const navigateToOrganization = () => {
    window.location.href = "https://github.com/AdoVelopers";
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Lomerio</title>
        <link rel="canonical" href="http://bryanlomerio.com/home" />
        <meta
          name="description"
          content="Bryan Lomerio | Home - Junior Backend Developer"
        />
      </Helmet>
      <Animate to="1" from="0" attributeName="opacity">
        <Particles params={particles} className="particle" />
        <Container className="App-header" fluid={true}>
          <img
            src={organizationLogo}
            alt="Organization Logo"
            className="organization-logo"
            onClick={navigateToOrganization}
          />
          <Row className="App-main">
            <Col xl={7} className="App-left">
              <HomeLeft />
            </Col>
            <Col xl={5} className="App-right">
              <HomeRight />
            </Col>
          </Row>
          <Row className="App-footer">
            <Footer />
          </Row>
        </Container>
      </Animate>
    </div>
  );
}

export default Home;
