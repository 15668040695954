import React, { Component } from "react";
import "../projects/style.css";
import { Container, Row } from "react-bootstrap";
import Footer from "../../components/footer";
import Animate from "react-smooth";
import { Helmet } from "react-helmet";
import HamburgerMenu from "../../components/hamburger_menu";
import particles from "../../const/project_particle.js";
import Particles from "react-particles-js";
import ProjectBody from "../../components/project_main";

class Projects extends Component {
  render() {
    return (
      <Container className="About-header" fluid={true}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Project | Bryan Lomerio</title>
          <link rel="canonical" href="http://bryanlomerio.com/project" />
          <meta
            name="description"
            content="Bryan Lomerio | About - As an enthusiastic practitioner in the field of Computer Science, I am deeply engrossed in programming, cybersecurity, and software engineering. My journey involves continuous exploration of diverse programming languages and tools to broaden my skill set and fortify my grasp on computer science concepts."
          />
        </Helmet>
        <Animate to="1" from="0" attributeName="opacity">
          <Row className="About-main">
            <Particles params={particles} className="particle" />
            <HamburgerMenu />
            <ProjectBody />
          </Row>
          <br />
          <Row className="About-footer">
            <Footer />
          </Row>
        </Animate>
      </Container>
    );
  }
}

export default Projects;
