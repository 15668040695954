import React, { useState } from "react";
import "../cv_left_content/style.css";
import Slide from "react-reveal/Slide";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHdd } from "@fortawesome/free-solid-svg-icons";
import { faGithubSquare } from "@fortawesome/free-brands-svg-icons";
import cv1 from "../../assets/img/Bryan Resume.png";
import Lightbox from "react-image-lightbox";
import { Modal, Button } from "react-bootstrap";
import Projects from "../projects";
import { Link } from "react-router-dom";

const images = [cv1];

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

const CLC = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow(!show);
  };

  return (
    <Slide left>
      <Bounce>
        <div className="clc_main">
          <div className="clc_container">
            <Fade top cascade>
              <h1 className="clc_header">About</h1>
            </Fade>
            <ColoredLine color="#FFC466" />
            <div className="yellow-line"></div>
            <Fade top cascade>
              <div className="AboutBtnContainer">
                <Link to={"./project"} className="left">
                  <FontAwesomeIcon icon={faHdd} className="left_icon" />
                  <br />
                  <br />
                  Projects & Experiences
                </Link>
                <div className="right">
                  <FontAwesomeIcon
                    onClick={() => setShow(true)}
                    icon={faGithubSquare}
                    className="right_icon"
                  />
                  <br />
                  <br />
                  Git Projects
                </div>
              </div>
            </Fade>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % images.length)
              }
            />
          )}
          <Modal show={show} size="xl" onHide={toggleModal} centered>
            <Modal.Header className="modalHeader" closeButton>
              <Projects />
            </Modal.Header>
            <Button
              onClick={toggleModal}
              variant="outline-light"
              size="lg"
              className="modal-exit-btn about_modal"
            >
              Close
            </Button>
          </Modal>
        </div>
      </Bounce>
    </Slide>
  );
};

export default CLC;
