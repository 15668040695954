import React from "react";
import { Row, Col } from "react-bootstrap";
import SocialIcons from "../social_icons";
import "./style.css"; // Moved import statement for style.css here

const ContactLeft = () => {
  return (
    <div>
      <Row className="Contact-text">
        <Col xl={12}>
          <h1 className="p-heading1">Get In Touch</h1>
          <p className="p-heading2">
            Hello <strong>Visitor !!</strong>
            <br />
            Feel free to contact me with any questions or ideas via the{" "}
            <strong>contact form</strong> or <strong>social media icons</strong>
            . Let's chat!
          </p>
        </Col>
      </Row>
      <Row className="contact-left-footer">
        <SocialIcons />
      </Row>
    </div>
  );
};

export default ContactLeft;
