import React, { Component } from "react";
import "../loading/style.css";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import PuffLoader from "react-spinners/PuffLoader";

class Loading extends Component {
  state = {
    redirect: false,
  };

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), 3000);
    ReactGA.initialize("Anino##123++");
    ReactGA.pageview("bryanlomerio.com - Loading Screen");
  }

  componentWillUnmount() {
    clearTimeout(this.id);
  }

  render() {
    return this.state.redirect ? (
      <Redirect to="/home" />
    ) : (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bryan Lomerio</title>
          <link rel="canonical" href="http://bryanlomerio.com/" />
          <meta
            name="description"
            content="Bryan Lomerio - Junior Backend Developer"
          />
        </Helmet>
        <div className="Loading-header">
          <div className="PuffLoader-container">
            <PuffLoader size={"40vw"} color={"#fff"} loading={true} />
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
